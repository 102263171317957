<template>
  <div v-if="Blog != null" v-html="Blog.contenido"></div>
</template>

<script>
export default {
  metaInfo: {
    title: "Oferta de Empleo | Ofimania",
  },
  data() {
    return {
      Blog: null,
    };
  },
  created() {
    this.$store.state.cargando = true;
    this.axios
      .get("https://www.ofimania.com/API/Blog/Get1")
      .then((response) => {
        this.$store.state.cargando = false;
        this.Blog = response.data[0];
      })
      .catch((error) => {
        this.$store.state.cargando = false;
        this.$store.state.alerta = {
          estado: true,
          tipo: "error",
          titulo: "Error de Conexion",
          info: "Verifique su Conexion a Internet",
        };
      });
  },
};
</script>

<style></style>
